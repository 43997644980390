import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import Content from 'components/Content'
import DynamicTextImage from 'components/DynamicTextImage'
import Modal from 'components/Modal'
import VideoPlayer from 'components/VideoPlayer'

import { Store } from 'Store'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'
import transDomain from './translations/index.translations'

function wrapText (string: string = '', maxLength: number = 16) {
    return string.replace(new RegExp(`(?![^\\n]{1,${maxLength}}$)([^\\n]{1,${maxLength}})\\s`, 'g'), '$1\n')
}

function SideBar (props) {
    const videoPlayer = React.useRef()
    const { state } = React.useContext(Store)
    const isConquest = !state.lead.fields.firstName || state.lead.fields.firstName === ''
    const text = props.theme.dynamicTextImage ? wrapText(state.lead.fields[props.theme.dynamicTextImage.fieldsKey]) : null
    const { t } = useTranslation(transDomain)
    const [ isModalOpen, setIsModalOpen ] = React.useState(false)

    const handleOpenModal = React.useCallback(() => {
        if (!state.campaign.videos.masthead) return alert('video not available')
        setIsModalOpen(true)
    }, [ state.campaign.videos.masthead ])

    const playVideo = React.useCallback(() => {
        videoPlayer.current.play()
    }, [])

    const handleClose = React.useEffect(() => {
        setIsModalOpen(false)
    }, [])

    return (
        <>
            <div className={styles.sideBarContainer} style={{
                '--background-image': `url(${props.theme.background})`,
                backgroundColor: props.theme.sidebarBackgroundColor,
            }}>
                <div className={cn(styles.img, {
                    [styles.isBaseLogo]: props.theme.isBaseLogo
                }, styles[props.theme.customLogoClassName])} style={{
                    backgroundImage: `url(${props.theme.logo})`
                }}/>

                <Content
                    style={{ color: props.theme.titleColor }}
                    wrapper='h2'
                    contentKey='sidebar.stats.header'
                    className={cn(styles.title, styles[props.theme.customTitleClassName])}
                >
                    {props.name}, thank you for being a part of their story.
                </Content>

                <div className={styles.body}>
                    {props.theme.signature && (
                        <div className={styles.signature}>
                            <span>Rachel Thompson, ‘20</span>
                            <div style={{ backgroundImage: `url(${props.theme.signature})` }} />
                        </div>
                    )}
                </div>

                <div className={styles.mobileBgImage} style={{
                    '--background-image-mobile': `url(${props.theme.backgroundMobile})`,
                }}/>

                {props.theme.dynamicTextImage && (
                    <div className={styles.dynamicTextImageContainer}>
                        <DynamicTextImage
                            backgroundImage={props.theme.dynamicTextImage.background}
                            text={
                                isConquest
                                    ? t(props.theme.dynamicTextImage.translationConquestKey, { text })
                                    : t(props.theme.dynamicTextImage.translationKey, { text })
                            }
                            position={props.theme.dynamicTextImage.position}
                            font={props.theme.dynamicTextImage.font}
                            className={styles.dynamicTextImage}
                        />
                        {props.theme.playIcon && (
                            <div
                                onClick={handleOpenModal}
                                className={styles.play}
                                style={{
                                    backgroundImage: `url(${props.theme.playIcon})`
                                }}
                            />
                        )}
                    </div>
                )}

                {(props.theme.playIcon && !props.theme.dynamicTextImage) && (
                    <div
                        onClick={handleOpenModal}
                        className={styles.play}
                        style={{
                            backgroundImage: `url(${props.theme.playIcon})`
                        }}
                    />
                )}
            </div>

            {state.campaign.videos.masthead && (
                <Modal afterOpen={playVideo} isOpen={isModalOpen} onCancel={handleClose}>
                    <VideoPlayer src={state.campaign.videos.masthead} ref={videoPlayer}/>
                </Modal>
            )}
        </>
    )
}

export default withTheme(supportedThemes)(SideBar)
