// @flow
import * as React from 'react'
import cn from 'classnames'
import Content from 'components/Content'

import withTheme from 'hoc/withTheme'
import { Store } from 'Store'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'

function Footer (props) {
    const { state } = React.useContext(Store)
    const address = state.campaign.client.address

    return (
        <div className={styles.container} style={{
            backgroundColor: props.theme.backgroundColor,
        }}>
            {/* <div className={styles.oval} style={{
                backgroundImage: `url(${props.theme.oval})`
            }} /> */}

            <div className={styles.innerContainer}>
                <Content
                    contentKey='pageFooter.message'
                    wrapper='p'
                    className={styles.disclaimer}
                >
                    With your help, we can support our students' dreams.
                </Content>
            </div>

            <img
                className={cn(styles.logo, styles[props.theme.customLogoClassName])}
                src={props.theme.logo}
                alt=''
            />

            <p className={styles.text}>
                <span>{[((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                    address.city,
                    address.province,
                    address.postalCode
                ].join(' ')} {state.campaign.contactInfo.phoneNumber ? `| phone: ${state.campaign.contactInfo.phoneNumber}` : null} {state.campaign.contactInfo.email ? `| email: ${state.campaign.contactInfo.email}` : null}</span>
            </p>
        </div>
    )
}

export default withTheme(supportedThemes)(Footer)
