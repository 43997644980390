import React from 'react'
import { updateLead } from 'Actions'
import { Store } from 'Store'

import Footer from 'components/Footer'
import SideBar from 'components/SideBar'
import Step1 from 'components/LeadGenForm/Step1'

import styles from './styles.module.scss'
import Payment from 'components/Payment'

export default function () {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const firstName = state.lead.fields.firstName

    const handleAbort = React.useCallback(() => {
        setSubmitted(false)
    }, [])

    const initialValues = React.useMemo(() => {
        const values = state.lead.fields
        values.anonymousDonation = state.lead.fields.anonymousDonation || false
        values.donationType = state.lead.fields.donationType || 'one-time'

        // has no donation selected by the user
        if (!values.hasOwnProperty('donationAmount') || values.donationAmount === null) {
            // if we have defaultDonationAmount set it as default
            if (state.campaign.website.defaultDonationAmount) {
                values.donationAmount = state.campaign.website.defaultDonationAmount
            }

            // if lead has previous donation use as default
            if (state.lead.fields.previousDonationAmount) {
                values.donationAmount = state.lead.fields.previousDonationAmount
            }
        }

        // if only 1 donationDesignation set is as default
        if (state.campaign.website.donationDesignations && state.campaign.website.donationDesignations.length === 1) {
            values.donationDesignations = [{
                type: state.campaign.website.donationDesignations[0],
                value: values.donationAmount
            }]
        }
        // if we have defaultDonationDesignation set is as default
        if (state.campaign.website.defaultDonationDesignation &&
            (typeof values.donationDesignations === 'undefined' || values.donationDesignations === null)
        ) {
            values.donationDesignations = [{
                type: state.campaign.website.defaultDonationDesignation,
                value: values.donationAmount
            }]
        }

        return values
    }, [
        state.campaign.website,
        state.lead.fields
    ])

    return (
        <>
            {submitted ? <Payment onAbort={handleAbort} /> : null}

            <div className={styles.grid}>
                <div className={styles.leftSide}>
                    <SideBar name={firstName}/>
                </div>

                <div className={styles.rightSide}>
                    <div>
                        <Step1
                            onSubmit={async (data, done) => {
                                try {
                                    let lead = {
                                        fields: data,
                                        isComplete: true
                                    }
                                    await updateLead(dispatch, state.code, lead)
                                    setSubmitted(true)
                                    window.scrollTo(0, 0)
                                } catch (err) {
                                    alert('Oops something went wrong')
                                    console.error(err)
                                    done(err)
                                }
                            }}
                            initialValues={initialValues}
                        />
                    </div>
                    <span><Footer /></span>
                </div>
            </div>
        </>
    )
}
